/**
 * Mesh developers page
 *
 * @author Anja Bisako
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { Container } from '../components/Container';
import { Page } from '../components/Page';
import { Body, HeaderH2, HeaderH5 } from '../components/Typography';
import { IndexLayout } from '../layouts';

const IndexPage: React.FC = () => {
	const { t } = useTranslation();

	return (
		<IndexLayout>
			<Page sectionBackground="lightBackground">
				<Container>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginTop: '2rem',
							marginBottom: '4rem',
						}}
					>
						<HeaderH2 style={{ marginBottom: '1rem' }}>{t('imprint.title')}</HeaderH2>
						{/* <Body>{t('imprint.text')}</Body> */}
						<HeaderH5 style={{ marginBottom: '1rem' }}>Firmenanschrift</HeaderH5>
						<Body>
							ThingOS GmbH & Co. KG
							<br />
							Naststr. 15b
							<br />
							70184 Stuttgart
							<br />
							Telefon: +49 711 21952 500
							<br />
							Mail: info@thingos.io
							<br />
							Webseite: www.thingos.io
							<br />
							Registergericht: Stuttgart, Deutschland
							<br />
							Handelsregisternummer: HRB 764636
							<br />
							Umsatzsteuer-IDNr.: DE355784139
						</Body>
						<HeaderH5 style={{ marginBottom: '1rem' }}>Webseite Operator</HeaderH5>
						<Body>
							ThingOS GmbH & Co. KG
							<br />
							Naststr. 15b
							<br />
							70184 Stuttgart
							<br />
							Telefon: +49 711 21952 500
							<br />
							Mail: info@thingos.io
							<br />
							Webseite: www.thingos.io
						</Body>
						<HeaderH5 style={{ marginBottom: '1rem' }}>Geschäftsführer</HeaderH5>
						<Body>
							Sibylle Thierer
							<br />
							Jörg Schmid
							<br />
							Thomas Kubitza
						</Body>
					</div>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
export default IndexPage;
